import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserDataFromAuth, getFirmsByUserId } from "../slices/thunks";

const AdminProtected = ({ children }) => {
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAdmin = async () => {
            try {
                const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
                if (!companyData.companyID) {
                    setLoading(false);
                    return;
                }

                const authResponse = await dispatch(getUserDataFromAuth());
                const userId = authResponse.id;
                const response = await dispatch(getFirmsByUserId(userId));
                const firm = response.find(firm => firm.firmId === companyData.companyID);
                
                setIsAdmin(firm?.firmAdmin || false);
                setLoading(false);
            } catch (error) {
                console.error("Firm admin check failed:", error);
                setLoading(false);
            }
        };

        checkAdmin();
    }, [dispatch]);

    if (loading) {
        return null;
    }

    if (!isAdmin) {
        return <Navigate to="/dashboard" />;
    }

    return children;
};

export default AdminProtected; 