import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Pagination = ({ 
    data, 
    currentPage, 
    setCurrentPage, 
    perPageData,
    showRecordInfo = true,
    maxVisiblePages = 5
}) => {
    const { t } = useTranslation();

    const totalPages = Math.ceil((data?.length || 0) / (perPageData || 10));

    const handleClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const halfVisible = Math.floor(maxVisiblePages / 2);

    useEffect(() => {
        if (totalPages > 0 && currentPage > totalPages) {
            setCurrentPage(totalPages);
        }
        if (currentPage < 1) {
            setCurrentPage(1);
        }
    }, [totalPages, currentPage, setCurrentPage]);
    
    if (!data?.length) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="d-flex justify-content-between mt-3">
                {showRecordInfo && (
                    <div className="text-muted">
                        {t("Pagination_Records")} {((currentPage - 1) * perPageData) + 1} - {Math.min(currentPage * perPageData, data?.length)} {t("Pagination_Of")} ({data?.length})
                    </div>
                )}
                <div>
                    <ul className="pagination mb-0">
                        <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                            <Link className="page-link text-capitalize" to="#" onClick={() => handleClick(currentPage - 1)}>{t("Pagination_Previous")}</Link>
                        </li>
                        
                        {(() => {
                            const pages = [];
                            
                            pages.push(
                                <li key={1} className={`page-item ${currentPage === 1 && 'active'}`}>
                                    <Link 
                                        className="page-link" 
                                        to="#" 
                                        onClick={() => handleClick(1)}
                                    >
                                        1
                                    </Link>
                                </li>
                            );

                            const startPage = Math.max(2, currentPage - halfVisible);
                            const endPage = Math.min(totalPages - 1, currentPage + halfVisible);

                            if (startPage > 2) {
                                pages.push(
                                    <li key="ellipsis1" className="page-item disabled">
                                        <span className="page-link">...</span>
                                    </li>
                                );
                            }

                            for (let i = startPage; i <= endPage; i++) {
                                if (i !== 1 && i !== totalPages) {
                                    pages.push(
                                        <li key={i} className={`page-item ${currentPage === i && 'active'}`}>
                                            <Link 
                                                className="page-link" 
                                                to="#" 
                                                onClick={() => handleClick(i)}
                                            >
                                                {i}
                                            </Link>
                                        </li>
                                    );
                                }
                            }

                            if (endPage < totalPages - 1) {
                                pages.push(
                                    <li key="ellipsis2" className="page-item disabled">
                                        <span className="page-link">...</span>
                                    </li>
                                );
                            }

                            if (totalPages > 1) {
                                pages.push(
                                    <li key={totalPages} className={`page-item ${currentPage === totalPages && 'active'}`}>
                                        <Link 
                                            className="page-link" 
                                            to="#" 
                                            onClick={() => handleClick(totalPages)}
                                        >
                                            {totalPages}
                                        </Link>
                                    </li>
                                );
                            }

                            return pages;
                        })()}

                        <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                            <Link className="page-link text-capitalize" to="#" onClick={() => handleClick(currentPage + 1)}>{t("Pagination_Next")}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Pagination;