export const POST_LOGIN = "/auth/login";
export const GET_PROFILEDATA = "/user/get-from-auth";
export const POST_LOGOUT = "/auth/revoke-token"
export const PUT_PROFILEDATA = "/user/from-auth";

export const ENABLE_2FA = "/auth/enable-otp-authenticator";
export const DISABLE_2FA = "/auth/revoke-authenticator";
export const VERIFY_2FA = "/auth/verify-otp-authenticator";

export const ENABLE_EMAILAUTH = "/auth/enable-email-authenticator";
export const VERIFY_EMAILAUTH = "/auth/verify-email-authenticator";

export const GET_USERS = "/user/list-by-firm-id?PageIndex=0&PageSize=20000";
export const CREATE_USER = "/user";
export const UPDATE_USER = "/user";
export const DELETE_USER = "/user";

export const REGISTER_USER_BY_EMAIL = "/auth/register";

export const GET_ROLES = "/role?PageIndex=0&PageSize=20000";
export const GET_ROLE_BY_ID = "/role/";
export const CREATE_ROLE = "/role";
export const DELETE_ROLE = "/role/";
export const UPDATE_ROLE = "/role";

export const ADD_USER_BANK_ACCOUNT = "/user-firm-bank-account-match";
export const REMOVE_USER_BANK_ACCOUNT = "/user-firm-bank-account-match";

export const ADD_USER_FIRM = "/user-firm-match";
export const ADD_USER_FIRM_BY_INVITE = "/user-firm-match/create-from-invitation/";
export const REMOVE_USER_FIRM = "/user-firm-match";
export const UPDATE_USER_FIRM = "/user-firm-match";

export const GET_SUB_FIRMS = "/firm/list-with-sub-firms/";
export const GET_FIRMS_BY_USER_ID = "/user-firm-match/list-by-user-id?PageIndex=0&PageSize=20000";
export const GET_BANK_ACCOUNT_BY_USER_ID = "/firm-bank-account/list-by-user-id?PageIndex=0&PageSize=20000";
export const GET_BANK_ACCOUNT_MATCH_BY_USER_ID = "/user-firm-bank-account-match/list-by-user-id/?PageIndex=0&PageSize=20000"
export const GET_LIST_WITH_BANKS = "/firm/list-with-banks/"
export const ADD_NOTIFICATION_PREFERENCE = "/transaction-notification-preference"

export const GET_USER_ROLES = "/user-role/list/";

export const GET_FIRM_BY_ID = "/firm/";
export const UPDATE_FIRM = "/firm";

export const GET_INVITE_DATA = "/user-firm-invitations/list/";
export const CREATE_INVITE = "/user-firm-invitations";
export const DELETE_INVITE = "/user-firm-invitations/";

export const GET_BANKS_2 = "/bank?PageIndex=0&PageSize=20000";
export const GET_BANKS = "/bank?PageIndex=0&PageSize=20000";
export const GET_FIRMS = "/firm?PageIndex=0&PageSize=20000";
export const GET_BANK_BRANCHES = "/bank-branch/list/";
export const GET_USER_BY_ID = "/user/";
export const GET_BANK_TRANSACTIONS = "/bank-integration-transaction";

export const GET_BANK_ACCOUNT = "/firm-bank-account/";
export const GET_BANK_ACCOUNT_BY_FIRM_ID = "/firm-bank-account/list/";
export const GET_BANK = "/bank/";
export const GET_BANK_BRANCH = "/bank-branch/";
export const GET_BALANCE_BY_CURRENCY = "/firm-bank-account/get-balance-by-bank-account";

export const GET_BALANCES_BY_BANK = "/bank?PageIndex=0&PageSize=20000";
export const GET_BALANCE_WITH_BANKS = "/firm-bank-account/get-balance-with-banks";

export const GET_FIRM_LICENSES = "/firm-licence/get-by-firm-id/";