import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, ModalHeader, ModalBody, ModalFooter, Modal, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, Spinner, Collapse } from 'reactstrap';
import { getUserDataFromAuth, changeUserInfo, enable2FA, enable2FAConfirm, disable2FA, enableEmailAuth, disableEmailAuth, getFirmsByUserId, getListWithBanks , userFirmBankAccountMatchList,addNotificationPreference } from "../../slices/thunks";
import { useNavigate } from 'react-router-dom';
import { sendNotify, useHandleError } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import Enable2FAPopup from "../../Components/Common/Enable2FAPopup";

const Profile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const handleError = useHandleError();
    const [loading, setLoading] = useState(false);
    const [emailLoading, setEmailLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    const [Email, setEmail] = useState("");
    const [authType, setAuthType] = useState("");
    const [userRole, setUserRole] = useState("");
    const [companies, setCompanies] = useState([]);
    const [openEnable2FAPopup, set2FAPopup] = useState(false);
    const [enable2FAResponse, setEnable2FAResponse] = useState(null);
    const [isCompaniesOpen, setIsCompaniesOpen] = useState(false);
    const [addNotificationInstructionPopup, setNotificationInstructionPopup] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [banks, setBanks] = useState([])
    const [selectedBank, setSelectedBank] = useState(null)
    const [branches, setBranches] = useState([])
    const [selectedBranch, setSelectedBranch] = useState(null)
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const toggleCompanies = () => setIsCompaniesOpen(!isCompaniesOpen);
    const [selectedUserFirmBankAccountMatchId,setSelectedUserFirmBankAccountMatchId]=useState(null)
    document.title = t('Profile_Title') + " | Tanasoft";

    useEffect(() => {
        dispatch(getUserDataFromAuth()).then(response => {
            const userId = response["id"];
            setFirstName(response["firstName"] || t("Unknown"));
            setLastName(response["lastName"] || t("Unknown"));
            setEmail(response["email"] || t("Unknown"));
            setAuthType(response["authenticatorType"] || "None");

            if (response.roles && response.roles.length > 0) {
                const userRole = response.roles[0].roleName;
                setUserRole(userRole);
            }

            dispatch(getFirmsByUserId(userId)).then(response => {
                console.log(response);
                const companyData = response.map(company => ({
                    firmId: company["firmId"],
                    firmName: company["firmName"],
                    vknNumber: company["firmVKN"],
                    userFirmMatchId:company["id"]
                }));
                
                setCompanies(companyData);

            }).catch(err => {
                handleError(err);
            });

        }).catch(err => {
            handleError(err);
        });
    }, []);
    const companyOptions = companies.map((company) => ({
        label: company.firmName,
        value: company.firmId,
        userFirmMatchId: company.userFirmMatchId
    }));
   
    const uniqueBankOptions = banks.reduce((acc, bank) => {
        if (!acc.seenBankNames.has(bank.bankName)) {
            acc.options.push({
                label: bank.bankName,
                value: bank.id,
                branches: bank.branches
            });
            acc.seenBankNames.add(bank.bankName);
        }
        return acc;
    }, { options: [], seenBankNames: new Set() }).options;
    
   
    
    
    const branchOptions = branches.map(branch => ({
        label: branch.branchName +" "+branch.accountNo,
        value: branch.id
    }))

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const onFirmChange = (selectedOption) => {
        console.log(selectedOption);
        setSelectedCompany(selectedOption)
        setBanks([])
        setSelectedBank(null)
        setBranches([])
        setSelectedBranch(null)
        getListWithBanks(selectedOption.value).then(response => {
            console.log(response);
            setBanks(response)

        }).catch(err => {
            handleError(err);
        });

    }

    const handleProfileFormSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const newPassword = data.get('newPasswordInput');
        const confirmPassword = data.get('confirmPasswordInput');

        if (newPassword !== confirmPassword) {
            sendNotify(t("Profile_PasswordsDontMatch"), 'danger');
            return;
        }

        const formDataObject = {
            firstname: data.get('firstnameInput'),
            lastname: data.get('lastnameInput'),
            email: data.get('emailInput'),
            password: data.get('passwordInput'),
            newPassword: data.get('newPasswordInput'),
            confirmPassword: data.get('confirmPasswordInput')
        };

        setLoading(true);
        dispatch(changeUserInfo(formDataObject)).then(() => {
            setLoading(false);
            navigate('/login');
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleEnable2FA = () => {
        setLoading(true);
        dispatch(enable2FA()).then((response) => {
            setLoading(false);
            setEnable2FAResponse(response);
            set2FAPopup(true);
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleDisable2FA = () => {
        setLoading(true);
        dispatch(disable2FA()).then(() => {
            setLoading(false);
            sendNotify(t('Popup_2FADisabled'), 'success');
            setAuthType("None");
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleEnable2FAConfirm = (code) => {
        if (code) {
            setLoading(true);
            dispatch(enable2FAConfirm(code)).then(() => {
                setLoading(false);
                sendNotify(t("Popup_2FAEnabled"), 'success');
                set2FAPopup(false);
                setAuthType("Otp");
            }).catch((err) => {
                setLoading(false);
                handleError(err);
            });
        } else {
            sendNotify(t("Popup_AuthCodeCannotBeEmpty"), 'danger');
        }
    };

    const handleEnableEmailAuth = () => {
        setEmailLoading(true);
        dispatch(enableEmailAuth()).then(() => {
            setEmailLoading(false);
            sendNotify(t('Popup_EmailAuthRequestSend'), 'success');
            setAuthType("Email");
        }).catch((err) => {
            setEmailLoading(false);
            handleError(err);
        });
    };

    const handleDisableEmailAuth = () => {
        setEmailLoading(true);
        dispatch(disableEmailAuth()).then(() => {
            setEmailLoading(false);
            sendNotify(t('Popup_EmailAuthDisabled'), 'success');
            setAuthType("None");
        }).catch((err) => {
            setEmailLoading(false);
            handleError(err);
        });
    };
    const handleAddNotificationInstructionPopupConfirm = () => {
        console.log(min,max);
        const requestBody = [{
        "userFirmBankAccountMatchId": selectedUserFirmBankAccountMatchId,
        "debitAmount": min,
        "creditAmount": max,
        }]
        addNotificationPreference(requestBody).then(response => {
            console.log(response);
            setSelectedUserFirmBankAccountMatchId(null)
            handleNotificationModalClose()
        }).catch(err=>{
            handleError(err)
        })
        

    };
    const handleNotificationModalClose = () => {
        setNotificationInstructionPopup(false)
        setSelectedCompany(null);
        setBanks([]);
        setSelectedBank(null);
        setBranches([]);
        setSelectedBranch(null);
        setMin(0)
        setMax(0)
    }

    const selectOpt = [{
        label:"işlem1",
        value:1
    },{
        label:"işlem2",
        value:2
    },{
        label:"işlem3",
        value:3
    },{
        label:"işlem4",
        value:4
    }]
    return (
        <React.Fragment>
            <div className="page-content pt-4 mb-4 mb-lg-3 pb-lg-4">
                <Container fluid>
                    <div className="position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg profile-setting-img"></div>
                    </div>
                    <Row>
                        <Col xxl={3}>
                            <Card className="mt-n5">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                                            <span className="rounded-circle avatar-xl img-thumbnail user-profile-image d-flex align-items-center justify-content-center bg-primary text-white fs-1">
                                                {FirstName?.charAt(0)}{LastName?.charAt(0)}
                                            </span>
                                        </div>
                                        <h5 className="fs-16 mb-1">{FirstName} {LastName}</h5>
                                        <p className="text-muted mb-0">{userRole}</p>
                                    </div>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardBody className="p-3">
                                    <div className="d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={toggleCompanies}>
                                        <div className="flex-grow-1">
                                            <h5 className="card-title mb-0 fs-15">{t('Profile_Companies')}</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <i className={`ri-arrow-down-s-line fs-18`}
                                                style={{
                                                    transform: isCompaniesOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                                    transition: 'transform 0.3s ease-in-out',
                                                    display: 'inline-block'
                                                }}>
                                            </i>
                                        </div>
                                    </div>
                                    <Collapse isOpen={isCompaniesOpen}>
                                        <div className="pt-3">
                                            {companies.map((company) => (
                                                <div key={company.firmId} className="border rounded p-3 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="flex-shrink-0">
                                                            <div className="avatar-sm">
                                                                <span className="avatar-title bg-light text-primary rounded fs-3">
                                                                    <i className="ri-building-line"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h6 className="fs-15 mb-1">{company.firmName}</h6>
                                                            <p className="text-muted mb-0">
                                                                {company.vknNumber}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            {companies.length === 0 && (
                                                <div className="text-center text-muted py-3">
                                                    {t('Profile_NoCompanies')}
                                                </div>
                                            )}
                                        </div>
                                    </Collapse>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xxl={9}>
                            <Card className="mt-xxl-n5">
                                <CardHeader>
                                    <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "1" })}
                                                onClick={() => {
                                                    tabChange("1");
                                                }}
                                                type="button">
                                                <i className="fas fa-home"></i>
                                                {t('Profile_UserInformation')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "2" })}
                                                onClick={() => {
                                                    tabChange("2");
                                                }}
                                                type="button">
                                                <i className="fas fa-home"></i>
                                                {t('Profile_2FATab')}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink to="#"
                                                className={classnames({ active: activeTab === "3" })}
                                                onClick={() => {
                                                    tabChange("3");
                                                }}
                                                type="button">
                                                <i className="fas fa-home"></i>
                                                {t('Profile_NotificationSettings')}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Form onSubmit={handleProfileFormSubmit}>
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="firstnameInput" className="form-label">{t('Profile_Firstname')}</Label>
                                                            <Input type="text" className="form-control" name='firstnameInput' id="firstnameInput" placeholder={t("Profile_FirstnamePlaceholder")} defaultValue={FirstName} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="lastnameInput" className="form-label">{t('Profile_Lastname')}</Label>
                                                            <Input type="text" className="form-control" name='lastnameInput' id="lastnameInput" placeholder={t("Profile_LastnamePlaceholder")} defaultValue={LastName} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="emailInput" className="form-label">{t('Profile_Email')}</Label>
                                                            <Input type="email" className="form-control" name='emailInput' id="emailInput" placeholder={t("Profile_EmailPlaceholder")} defaultValue={Email} disabled />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="passwordInput" className="form-label">{t('Profile_Password')} <span className="text-danger">*</span></Label>
                                                            <Input type="password" className="form-control" name='passwordInput' id="passwordInput" placeholder={t("Profile_PasswordPlaceholder")} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="newPasswordInput" className="form-label">{t('Profile_NewPassword')}</Label>
                                                            <Input type="password" className="form-control" name="newPasswordInput" id="newPasswordInput" placeholder={t('Profile_NewPasswordPlaceholder')} />
                                                        </div>
                                                    </Col>

                                                    <Col lg={4}>
                                                        <div>
                                                            <Label htmlFor="confirmPasswordInput" className="form-label">{t('Profile_ConfirmPassword')}</Label>
                                                            <Input type="password" className="form-control" name='confirmPasswordInput' id="confirmPasswordInput" placeholder={t('Profile_ConfirmPasswordPlaceholder')} />
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <Button type="submit" color="primary" disabled={loading}>
                                                                {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                                {t('Profile_UpdateButton')}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </TabPane>

                                        <TabPane tabId="2">
                                            <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                                                <div className="flex-grow-1">
                                                    <h6 className="fs-14 mb-1">{t("Profile_2FA")}</h6>
                                                    <p className="text-muted">{t('Profile_Enable2FADesc')}</p>
                                                </div>
                                                <div className="flex-shrink-0 ms-sm-3">
                                                    {authType === "Otp" ? (
                                                        <Button type="button" color="danger" className="btn btn-danger" onClick={handleDisable2FA} disabled={loading}>
                                                            {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_Disable2FABtn")}
                                                        </Button>
                                                    ) : (
                                                        <Button type="button" color="primary" onClick={handleEnable2FA} disabled={loading}>
                                                            {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_Enable2FABtn")}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column flex-sm-row mb-4 mb-sm-0">
                                                <div className="flex-grow-1">
                                                    <h6 className="fs-14 mb-1">{t("Profile_EmailAuth")}</h6>
                                                    <p className="text-muted">{t('Profile_EnableEmailAuthDesc')}</p>
                                                </div>
                                                <div className="flex-shrink-0 ms-sm-3">
                                                    {authType === "Email" ? (
                                                        <Button type="button" color="danger" className="btn btn-danger" onClick={handleDisableEmailAuth} disabled={emailLoading}>
                                                            {emailLoading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_DisableEmailAuth")}
                                                        </Button>
                                                    ) : (
                                                        <Button type="button" color="primary" onClick={handleEnableEmailAuth} disabled={emailLoading}>
                                                            {emailLoading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                                                            {t("Profile_EnableEmailAuth")}
                                                        </Button>
                                                    )}
                                                </div>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId="3">
                                            <div className="text-capitalize">
                                                <Button color="primary" className="d-flex justify-content-center align-items-center"
                                                    onClick={() => setNotificationInstructionPopup(true)}>
                                                    <i className="bx bx-plus me-2" style={{ fontSize: "20px" }}></i>
                                                    <span className="text-capitalize">{t("AdminSettings_BildirimTalimatiEkle")}</span>
                                                </Button>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Enable2FAPopup isOpen={openEnable2FAPopup} toggle={() => set2FAPopup(!openEnable2FAPopup)} onConfirm={handleEnable2FAConfirm} value={enable2FAResponse} loading={loading} />

            <Modal isOpen={addNotificationInstructionPopup} toggle={() => setNotificationInstructionPopup(!addNotificationInstructionPopup)} >
                <ModalHeader toggle={handleNotificationModalClose}>{t("AdminSettings_BildirimTalimatiEkle")}</ModalHeader>
                <ModalBody>
                    <Form >
                        <FormGroup>
                            <Label htmlFor="banka" className="form-label text-capitalize">
                                {t("AdminSettings_FirmaAdi")}
                            </Label>

                            <Select
                                className="text-capitalize"
                                placeholder={t("SelectCompanyPopup_Title")}
                                value={selectedCompany}
                                onChange={(selectedOption) => onFirmChange(selectedOption)}
                                options={companyOptions}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="banka" className="form-label text-capitalize">
                                {t("HesapHareketRaporu_Banka")}
                            </Label>
                            <Select
                                className="text-capitalize"
                                placeholder={t("HesapHareketRaporu_BankaSecin")}
                                value={selectedBank}
                                onChange={(selectedOption) => {
                                    setSelectedBank(selectedOption);
                                    setSelectedBranch(null)
                                    console.log(selectedOption);
                                    const filteredAccounts = banks.filter(account => account.bankName==selectedOption.label)
                                    
                                   
                                    setBranches(filteredAccounts);
                                }}
                                options={uniqueBankOptions}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="bankAccount" className="form-label text-capitalize">
                                {t("Hesap Adı")}
                            </Label>
                            <Select
                                className="text-capitalize"
                                value={selectedBranch}
                                isClearable
                                placeholder={t("Hesap Adı")}
                                onChange={(selectedOption) => {console.log(selectedOption); setSelectedBranch(selectedOption);
                                    
                                    userFirmBankAccountMatchList(selectedOption.value).then(response => {
                                       console.log(
                                        response.items.filter(item => item.userFirmMatchId == selectedCompany.userFirmMatchId)[0].id
                                        )
                                        setSelectedUserFirmBankAccountMatchId(response.items.filter(item => item.userFirmMatchId == selectedCompany.userFirmMatchId)[0].id)
                                    }).catch(err => {
                                        handleError(err);
                                    });
                                    
                                
                                }}
                                options={branchOptions}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <Label htmlFor="" className="form-label text-capitalize">
                                        {t("Min-Tutar")}
                                    </Label>
                                    <Input
                                        type="number"
                                        className="form-control text-capitalize"
                                        id=""
                                        onChange={(e)=> setMin(e.target.value)}
                                        placeholder={t("Min-Tutar")}
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="" className="form-label text-capitalize">
                                        {t("Max-Tutar")}
                                    </Label>
                                    <Input
                                        type="number"
                                        className="form-control text-capitalize"
                                        id=""
                                        onChange={e=>setMax(e.target.value)}
                                        placeholder={t("Max-Tutar")}
                                    />
                                </div>
                            </div>

                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="" className="form-label text-capitalize">
                                {t("HesapHareketleri_IslemTuru")}
                            </Label>
                            <Select
                                className="text-capitalize"
                                //value={selectedBranch}
                                isClearable
                                placeholder={t("HesapHareketleri_IslemTuru")}
                               // onChange={(selectedOption) => setSelectedBranch(selectedOption)}
                                options={selectOpt}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddNotificationInstructionPopupConfirm} type="submit">
                        {loading ? <Spinner size="sm" className='me-2'> {t("AdminSettings_Yukleniyor")} </Spinner> : null}
                        {t("AdminSettings_BildirimTalimatiEkle")}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default Profile;