import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { getUsers, addUser, addUserToFirm, deleteUser, editUser, getFirmBankAccounts, getSubFirms, getFirmsByUserId, getFirmBankAccountsByUserId } from "../../slices/thunks";
import { sendNotify, useHandleError } from "../../helpers/utils";
import { Button, Card, CardBody, Col, Container, Input, Label, Row, Table, Form, FormGroup, ModalHeader, ModalBody, ModalFooter, Modal, Nav, NavItem, NavLink, TabContent, TabPane, Spinner } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useTranslation } from "react-i18next";
import classnames from 'classnames';
import '../../assets/scss/themes.scss';
import Pagination from '../../Components/Common/Pagination';

const Users = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleError = useHandleError();
    document.title = t("AdminSettings_Users") + " | Tanasoft";

    const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
    const [loading, setLoading] = useState(false);
    const [usersList, setUserList] = useState([]);
    const [filteredUsersList, setFilteredUsersList] = useState([]);
    const [searchEmail, setSearchEmail] = useState('');
    const [searchName, setSearchName] = useState('');
    const [searchVerificationType, setSearchVerificationType] = useState('');
    const [addUserPopup, setAddUserPopup] = useState(false);
    const [editUserPopup, setEditUserPopup] = useState(false);
    const [newUserFirstname, setNewUserFirstname] = useState('');
    const [newUserLastname, setNewUserLastname] = useState('');
    const [newUserEmail, setNewUserEmail] = useState('');
    const [newUserPassword, setNewUserPassword] = useState('');
    const [newUserFirmAdmin, setNewUserFirmAdmin] = useState(false);
    const [editedUserId, setEditedUserId] = useState(null);
    const [editedUserFirstname, setEditedUserFirstname] = useState('');
    const [editedUserLastname, setEditedUserLastname] = useState('');
    const [editedUserEmail, setEditedUserEmail] = useState('');
    const [editedUserPassword, setEditedUserPassword] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [activeTab, setActiveTab] = useState('1');
    const [subTab, setSubTab] = useState('a');
    const [firmBankAccounts, setFirmBankAccounts] = useState([]);
    const [filteredFirmBankAccounts, setFilteredFirmBankAccounts] = useState([]);
    const [selectedBankAccounts, setSelectedBankAccounts] = useState([]);
    const [initialSelectedBankAccounts, setInitialSelectedBankAccounts] = useState([]);
    const [searchBankAccount, setSearchBankAccount] = useState('');
    const [subFirms, setSubFirms] = useState([]);
    const [filteredSubFirms, setFilteredSubFirms] = useState([]);
    const [selectedSubFirms, setSelectedSubFirms] = useState([]);
    const [initialSelectedSubFirms, setInitialSelectedSubFirms] = useState([]);
    const [searchSubFirms, setSearchSubFirms] = useState('');
    const itemsPerPage = 10;
    const handleChangePage = (page) => setCurrentPage(page);
    const displayedUsersList = filteredUsersList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    useEffect(() => {
        fetchUserData();
        fetchFirmBankAccounts();
        fetchSubFirms();
    }, []);

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const fetchUserData = async () => {
        dispatch(getUsers(companyData.companyID)).then((response) => {
            setUserList(response);
        }).catch((err) => {
            handleError(err);
        });
    };

    const fetchFirmBankAccounts = async () => {
        dispatch(getFirmBankAccounts(companyData.companyID)).then((response) => {
            setFirmBankAccounts(response);
            setFilteredFirmBankAccounts(response);
        }).catch((err) => {
            handleError(err);
        });
    };

    const fetchUserBankAccounts = async (userId) => {
        dispatch(getFirmBankAccountsByUserId(userId, false)).then((response) => {
            const userBankAccounts = response.map(account => ({
                id: account.id,
                firmBankAccountId: account.firmBankAccountId
            }));
            setSelectedBankAccounts(userBankAccounts);
            setInitialSelectedBankAccounts(userBankAccounts);
        }).catch((err) => {
            handleError(err);
        });
    };

    const fetchSubFirms = async () => {
        dispatch(getSubFirms(companyData.companyID)).then((response) => {
            setSubFirms(response);
            setFilteredSubFirms(flattenSubFirms(response));
        }).catch((err) => {
            handleError(err);
        });
    };

    const fetchUserSubFirms = async (userId) => {
        dispatch(getFirmsByUserId(userId)).then((response) => {
            const userSubFirmIds = response.map(firm => ({
                id: firm.id,
                firmId: firm.firmId,
                firmAdmin: firm.firmAdmin
            }));
            setSelectedSubFirms(userSubFirmIds);
            setInitialSelectedSubFirms(userSubFirmIds);
        }).catch((err) => {
            handleError(err);
        });
    };

    const flattenSubFirms = (firms, level = 0) => {
        return firms.reduce((acc, firm) => {
            acc.push({ ...firm, level });
            if (firm.subFirms && firm.subFirms.length > 0) {
                acc.push(...flattenSubFirms(firm.subFirms, level + 1));
            }
            return acc;
        }, []);
    };

    useEffect(() => {
        let filtered = usersList;

        if (searchName) {
            filtered = filtered.filter(user =>
                (user.firstName && user.firstName.toLocaleLowerCase('tr-TR').includes(searchName.toLocaleLowerCase('tr-TR'))) ||
                (user.lastName && user.lastName.toLocaleLowerCase('tr-TR').includes(searchName.toLocaleLowerCase('tr-TR')))
            );
        }

        if (searchEmail) {
            filtered = filtered.filter(user => user.email.toLowerCase().includes(searchEmail.toLowerCase()));
        }

        if (searchVerificationType) {
            filtered = filtered.filter(user => {
                const displayType = getUserDisplayAuthenticatorType(user.authenticatorType);
                return displayType.toLowerCase().includes(searchVerificationType.toLowerCase());
            });
        }

        setFilteredUsersList(filtered);
        setCurrentPage(1);
    }, [searchName, searchEmail, searchVerificationType, usersList]);

    const authenticatorTypeMap = {
        "None": t("AdminSettings_KullaniciEkle_DogrulamaTuru_Ayarlanmamis"),
        "Otp": "2FA",
        "Email": "E-Posta"
    };

    const getUserDisplayAuthenticatorType = (type) => {
        return authenticatorTypeMap[type] || "";
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allIds = displayedUsersList.map((_, index) => (currentPage - 1) * itemsPerPage + index);
            setSelectedItems(allIds);
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelectItem = (index) => {
        if (selectedItems.includes(index)) {
            setSelectedItems(selectedItems.filter((item) => item !== index));
        } else {
            setSelectedItems([...selectedItems, index]);
        }
    };

    const handleAddUserPopupConfirm = () => {
        e.preventDefault();
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!newUserEmail || !emailPattern.test(newUserEmail)) {
            sendNotify(t("AdminSettings_KullaniciEkle_EPostaUyari"), 'danger');
            return;
        }

        const formData = {
            firstName: newUserFirstname,
            lastName: newUserLastname,
            email: newUserEmail,
            password: newUserPassword
        };

        setLoading(true);
        dispatch(addUser(formData)).then((response) => {
            dispatch(addUserToFirm(response.id, companyData.companyID, newUserFirmAdmin)).then(() => {
                setAddUserPopup(false);
                setNewUserFirstname('');
                setNewUserLastname('');
                setNewUserEmail('');
                setNewUserPassword('');
                setNewUserFirmAdmin(false);
                fetchUserData();
                sendNotify(t("AdminSettings_KullaniciEkle_KullaniciEklendi"), 'success');
                setLoading(false);
            }).catch((err) => {
                setLoading(false);
                handleError(err);
            });
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleEditUser = (userId) => {
        const user = usersList.find(user => user.id === userId);
        setEditedUserId(user.id);
        setEditedUserFirstname(user.firstName || '');
        setEditedUserLastname(user.lastName || '');
        setEditedUserEmail(user.email || '');
        setEditedUserPassword('');
        setEditUserPopup(true);
        fetchUserSubFirms(userId);
        fetchUserBankAccounts(userId);
    };

    const handleEditUserPopupConfirm = () => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!editedUserEmail || !emailPattern.test(editedUserEmail)) {
            sendNotify(t("AdminSettings_KullaniciEkle_EPostaUyari"), 'danger');
            return;
        }

        const formData = {
            id: editedUserId,
            userFirmMatchId: selectedSubFirms.find(firm => firm.firmId === companyData.companyID)?.id || null,
            firstName: editedUserFirstname,
            lastName: editedUserLastname,
            email: editedUserEmail,
            password: editedUserPassword,
            bankAccounts: firmBankAccounts.map(account => {
                const selectedAccount = selectedBankAccounts.find(selected => selected.firmBankAccountId === account.id);
                const initialAccount = initialSelectedBankAccounts.find(initial => initial.firmBankAccountId === account.id);

                if (selectedAccount && !initialAccount) {
                    return { action: 'add', firmBankAccountId: selectedAccount.firmBankAccountId };
                } else if (!selectedAccount && initialAccount) {
                    return { action: 'remove', id: initialAccount.id };
                }
                return null;
            }).filter(account => account !== null),
            subFirms: filteredSubFirms.map(firm => {
                const selectedFirm = selectedSubFirms.find(selected => selected.firmId === firm.id);
                const initialFirm = initialSelectedSubFirms.find(initial => initial.firmId === firm.id);

                if (selectedFirm && !initialFirm) {
                    return { action: 'add', firmId: selectedFirm.firmId, firmAdmin: selectedFirm.firmAdmin };
                } else if (!selectedFirm && initialFirm) {
                    return { action: 'remove', id: initialFirm.id };
                } else if (selectedFirm && initialFirm && selectedFirm.firmAdmin !== initialFirm.firmAdmin) {
                    return { action: 'update', id: initialFirm.id, firmAdmin: selectedFirm.firmAdmin };
                }
                return null;
            }).filter(firm => firm !== null),
        };

        setLoading(true);
        dispatch(editUser(formData)).then(() => {
            setEditUserPopup(false);
            fetchUserData();
            sendNotify(t("AdminSettings_KullaniciEkle_KullaniciGuncellendi"), 'success');
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            handleError(err);
        });
    };

    const handleFirmAdminPermission = (subFirmId) => {
        setSelectedSubFirms(prevSelected => {
            const existingFirm = prevSelected.find(firm => firm.firmId === subFirmId);

            if (existingFirm) {
                return prevSelected.map(firm =>
                    firm.firmId === subFirmId
                        ? { ...firm, firmAdmin: !firm.firmAdmin }
                        : firm
                );
            } else {
                return [...prevSelected, { firmAdmin: true }];
            }
        });
    };

    const handleFirmPermission = (subFirmId) => {
        setSelectedSubFirms(prevSelected => {
            const isSelected = prevSelected.some(firm => firm.firmId === subFirmId);

            if (isSelected) {
                return prevSelected.filter(firm => firm.firmId !== subFirmId);
            } else {
                return [...prevSelected, { firmId: subFirmId }];
            }
        });
    };

    const handleBankAccountPermission = (id) => {
        setSelectedBankAccounts(prevSelected => {
            const isSelected = prevSelected.some(account => account.firmBankAccountId === id);

            if (isSelected) {
                return prevSelected.filter(account => account.firmBankAccountId !== id);
            } else {
                const account = filteredFirmBankAccounts.find(account => account.id === id);
                return [...prevSelected, { firmBankAccountId: account.id }];
            }
        });
    };

    const handleSelectAllBankAccounts = () => {
        setSelectedBankAccounts(prevSelected => {
            const selectedIds = prevSelected.map(account => account.firmBankAccountId);
            const allSelected = filteredFirmBankAccounts.every(account => selectedIds.includes(account.id));

            if (allSelected) {
                return [];
            } else {
                return filteredFirmBankAccounts.map(account => ({ firmBankAccountId: account.id }));
            }
        });
    };

    const handleDeleteUser = (userId) => {
        setLoading(true);
        dispatch(deleteUser(userId)).then(() => {
            fetchUserData();
            sendNotify(t("AdminSettings_KullaniciEkle_KullaniciSilindi"), 'success');
            setLoading(false);
        }).catch((err) => {
            handleError(err);
            setLoading(false);
        });
    };

    const handleSearchBankAccount = (e) => {
        const searchTerm = e.target.value;
        setSearchBankAccount(searchTerm);
        const filtered = firmBankAccounts.filter(account =>
            account.bankName.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
            account.branchName.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
            account.accountNo.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
            account.bankAccountType.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))
        );
        setFilteredFirmBankAccounts(filtered);
    };

    const handleSearchSubFirm = (e) => {
        const searchTerm = e.target.value;
        setSearchSubFirms(searchTerm);
        const filtered = flattenSubFirms(subFirms).filter(subFirm =>
            subFirm.name.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR')) ||
            subFirm.vknNumber.toLocaleLowerCase('tr-TR').includes(searchTerm.toLocaleLowerCase('tr-TR'))
        );
        setFilteredSubFirms(filtered);
    };

    // Bu fonksiyon ilerleyen zamanlarda isteğe göre açılabilir. -Efe
    // const handleSelectAllSubFirms = () => {
    //     setSelectedSubFirms(prevSelected => {
    //         if (prevSelected.length === filteredSubFirms.length) {
    //             return [];
    //         } else {
    //             return filteredSubFirms.map(subFirm => subFirm.id);
    //         }
    //     });
    // };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("AdminSettings_Users")} pageTitle={t("AdminSettings")} pageLink="dashboard" />
                    <Col xs={12}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="mb-4">
                                            <Col md={12}>
                                                <h5 className="card-title">{t("AdminSettings_KullaniciAra")}</h5>
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="searchName">{t("AdminSettings_AdSoyad")}</Label>
                                                    <Input type="text" id="searchName" placeholder={t("AdminSettings_AdSoyadIleAra")} value={searchName} onChange={(e) => setSearchName(e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="searchEmail">{t("AdminSettings_EPosta")}</Label>
                                                    <Input type="text" id="searchEmail" placeholder={t("AdminSettings_EPostaIleAra")} value={searchEmail} onChange={(e) => setSearchEmail(e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="searchVerificationType">{t("AdminSettings_DoğrulamaTürü")}</Label>
                                                    <Input type="text" id="searchVerificationType" placeholder={t("AdminSettings_DoğrulamaTürüIleAra")} value={searchVerificationType} onChange={(e) => setSearchVerificationType(e.target.value)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="mb-3 text-capitalize">
                                            <Button color="primary" className="d-flex justify-content-center align-items-center"
                                                onClick={() => setAddUserPopup(true)}>
                                                <i className="bx bx-plus me-2" style={{ fontSize: "20px" }}></i>
                                                <span className="text-capitalize">{t("AdminSettings_KullaniciEkle")}</span>
                                            </Button>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="checkAll" value="option" onChange={handleSelectAll} checked={selectedItems.length === displayedUsersList.length} />
                                                            </div>
                                                        </th>
                                                        <th className="text-capitalize">{t("AdminSettings_AdSoyad")}</th>
                                                        <th className="text-capitalize">{t("AdminSettings_EPosta")}</th>
                                                        <th className="text-capitalize">{t("AdminSettings_DoğrulamaTürü")}</th>
                                                        <th className="text-capitalize">{t("AdminSettings_Islemler")}</th>
                                                    </tr>
                                                </thead>
                                                {displayedUsersList.length >= 1 && (
                                                    <tbody>
                                                        {displayedUsersList.map((usersList, index) => {
                                                            const globalIndex = (currentPage - 1) * itemsPerPage + index;
                                                            const isChecked = selectedItems.includes(globalIndex);
                                                            return (
                                                                <tr key={index} className={isChecked ? "table-active" : ""}>
                                                                    <td>
                                                                        <div className="form-check">
                                                                            <Input className="form-check-input" type="checkbox" name="chk_child" value="option1" checked={isChecked} onChange={() => handleSelectItem(globalIndex)} />
                                                                        </div>
                                                                    </td>
                                                                    <td className="text-capitalize">{usersList.firstName || usersList.lastName ? `${usersList.firstName || ""} ${usersList.lastName || ""}`.trim() : "Belirtilmemiş"}</td>
                                                                    <td className="text">{usersList.email}</td>
                                                                    <td className="text">{usersList.authenticatorType === "None" ? t("AdminSettings_KullaniciEkle_DogrulamaTuru_Ayarlanmamis") : usersList.authenticatorType === "Otp" ? "2FA" : usersList.authenticatorType === "Email" ? "E-Posta" : ""}</td>
                                                                    <td>
                                                                        <Button color="primary" style={{ marginRight: '10px' }} onClick={() => handleEditUser(usersList.id)}>{t("AdminSettings_Islemler_Düzenle")}</Button>
                                                                        <Button color="danger" onClick={() => handleDeleteUser(usersList.id)} disabled={loading}>{t("AdminSettings_Islemler_Sil")}</Button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                )}
                                            </Table>

                                            {displayedUsersList.length <= 0 && (
                                                <Row>
                                                    <div style={{ width: "100%", height: 45 }} className="d-flex justify-content-center align-items-center border-2 border-bottom">
                                                        Kayıt Bulunamadı
                                                    </div>
                                                </Row>
                                            )}
                                        </div>
                                        <Pagination 
                                            data={usersList}
                                            currentPage={currentPage}
                                            setCurrentPage={handleChangePage}
                                            perPageData={itemsPerPage}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>

            <Modal isOpen={addUserPopup} toggle={() => setAddUserPopup(!addUserPopup)} onSubmit={handleAddUserPopupConfirm}>
                <ModalHeader toggle={() => setAddUserPopup(false)}>{t("AdminSettings_KullaniciEkle")}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleAddUserPopupConfirm}>
                        <FormGroup>
                            <Label for="newUserFirstname">{t("AdminSettings_KullaniciEkle_Ad")}</Label>
                            <Input type="text" id="newUserFirstname" placeholder={t("AdminSettings_KullaniciEkle_Ad")} value={newUserFirstname} onChange={(e) => setNewUserFirstname(e.target.value)} autoComplete="new-password" required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="newUserLastname">{t("AdminSettings_KullaniciEkle_Soyad")}</Label>
                            <Input type="text" id="newUserLastname" placeholder={t("AdminSettings_KullaniciEkle_Soyad")} value={newUserLastname} onChange={(e) => setNewUserLastname(e.target.value)} autoComplete="new-password" required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="newUserEmail">{t("AdminSettings_KullaniciEkle_EPosta")}</Label>
                            <Input type="email" id="newUserEmail" placeholder={t("AdminSettings_KullaniciEkle_EPosta")} value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} autoComplete="new-password" required />
                        </FormGroup>
                        <FormGroup>
                            <Label for="newUserPassword">{t("AdminSettings_KullaniciEkle_Sifre")}</Label>
                            <Input type="password" id="newUserPassword" placeholder={t("AdminSettings_KullaniciEkle_Sifre")} value={newUserPassword} onChange={(e) => setNewUserPassword(e.target.value)} autoComplete="new-password" required />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" id="newUserFirmAdmin" checked={newUserFirmAdmin} onChange={(e) => setNewUserFirmAdmin(e.target.checked)} />{' '}
                                {t("AdminSettings_FirmaYoneticisiMi")}
                            </Label>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleAddUserPopupConfirm} type="submit" disabled={loading || !newUserFirstname || !newUserLastname || !newUserEmail || !newUserPassword}>
                        {loading ? <Spinner size="sm" className='me-2'> {t("AdminSettings_Yukleniyor")} </Spinner> : null}
                        {t("AdminSettings_Islemler_Ekle")}
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={editUserPopup} toggle={() => setEditUserPopup(!editUserPopup)} onSubmit={handleEditUserPopupConfirm} size="lg">
                <ModalHeader toggle={() => setEditUserPopup(false)}>{t("AdminSettings_KullaniciyiDuzenle")}</ModalHeader>
                <ModalBody>
                    <Nav tabs className="mb-3">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => { toggleTab('1'); }}
                                style={{ cursor: 'pointer' }}
                            >
                                {t("AdminSettings_GenelBilgiler")}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => { toggleTab('2'); }}
                                style={{ cursor: 'pointer' }}
                            >
                                {t("AdminSettings_KullaniciYetkileri")}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <Form>
                                <FormGroup>
                                    <Label for="editedUserFirstname">{t("AdminSettings_KullaniciEkle_Ad")}</Label>
                                    <Input type="text" id="editedUserFirstname" placeholder={t("AdminSettings_KullaniciEkle_Ad")} value={editedUserFirstname} onChange={(e) => setEditedUserFirstname(e.target.value)} autoComplete="new-password" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="editedUserLastname">{t("AdminSettings_KullaniciEkle_Soyad")}</Label>
                                    <Input type="text" id="editedUserLastname" placeholder={t("AdminSettings_KullaniciEkle_Soyad")} value={editedUserLastname} onChange={(e) => setEditedUserLastname(e.target.value)} autoComplete="new-password" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="editedUserEmail">{t("AdminSettings_KullaniciEkle_EPosta")}</Label>
                                    <Input type="email" id="editedUserEmail" placeholder={t("AdminSettings_KullaniciEkle_EPosta")} value={editedUserEmail} onChange={(e) => setEditedUserEmail(e.target.value)} autoComplete="new-password" />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="editedUserPassword">{t("AdminSettings_KullaniciEkle_Sifre")}</Label>
                                    <Input type="password" id="editedUserPassword" placeholder={t("AdminSettings_KullaniciEkle_Sifre")} value={editedUserPassword} onChange={(e) => setEditedUserPassword(e.target.value)} autoComplete="new-password" />
                                </FormGroup>
                            </Form>
                        </TabPane>
                        <TabPane tabId="2">
                            <Nav tabs className="mb-3">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' && subTab === 'a' })}
                                        onClick={() => { toggleTab('2'); setSubTab('a'); }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {t("AdminSettings_KullaniciyaBagliFirmalar")}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' && subTab === 'b' })}
                                        onClick={() => { toggleTab('2'); setSubTab('b'); }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {t("AdminSettings_KullaniciyaBagliBankaHesaplari")}
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            {subTab === 'a' && (
                                <div>
                                    <div className="mb-3">
                                        <Input
                                            type="text"
                                            placeholder={t("AdminSettings_FirmaAra")}
                                            value={searchSubFirms}
                                            onChange={handleSearchSubFirm}
                                        />
                                    </div>
                                    {filteredSubFirms.length > 0 ? (
                                        <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                            {/* <div className="mb-3">
                                            <Button color="primary" onClick={() => handleSelectAllSubFirms()}>
                                                Tümüne İzin Ver
                                            </Button> Burası ilerleyen zamanlarda isteğe göre açılabilir. -Efe
                                        </div> */}
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t("AdminSettings_FirmaAdi")}</th>
                                                        <th>{t("AdminSettings_VKN")}</th>
                                                        <th>{t("AdminSettings_FirmaYoneticisiMi")}</th>
                                                        <th>{t("AdminSettings_IzinVer")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredSubFirms.map((subFirm, index) => (
                                                        <tr key={index}>
                                                            <td style={{ paddingLeft: `${subFirm.level * 20}px` }}>{subFirm.name}</td>
                                                            <td>{subFirm.vknNumber}</td>
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={selectedSubFirms.some(selected => selected.firmId === subFirm.id && selected.firmAdmin)}
                                                                    onChange={() => handleFirmAdminPermission(subFirm.id)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={selectedSubFirms.some(selected => selected.firmId === subFirm.id)}
                                                                    onChange={() => handleFirmPermission(subFirm.id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (
                                        <p className="text-center">{t("AdminSettings_FirmaBulunamadi")}</p>
                                    )}
                                </div>
                            )}
                            {subTab === 'b' && (
                                <div>
                                    <div className="mb-3">
                                        <Input
                                            type="text"
                                            placeholder={t("AdminSettings_BankaHesabiAra")}
                                            value={searchBankAccount}
                                            onChange={handleSearchBankAccount}
                                        />
                                    </div>
                                    {filteredFirmBankAccounts.length > 0 ? (
                                        <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                            <div className="mb-3">
                                                <Button color="primary" onClick={() => handleSelectAllBankAccounts()}>
                                                    {t("AdminSettings_TumuneIzinVer")}
                                                </Button>
                                            </div>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{t("AdminSettings_BankaAdi")}</th>
                                                        <th>{t("AdminSettings_BankaSubesi")}</th>
                                                        <th>{t("AdminSettings_HesapNumarasi")}</th>
                                                        <th>{t("AdminSettings_HesapTuru")}</th>
                                                        <th>{t("AdminSettings_IzinVer")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredFirmBankAccounts.map((account, index) => (
                                                        <tr key={index}>
                                                            <td>{account.bankName}</td>
                                                            <td>{account.branchName}</td>
                                                            <td>{account.accountNo}</td>
                                                            <td>{account.bankAccountType}</td>
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    checked={selectedBankAccounts.some(selected => selected.firmBankAccountId === account.id)}
                                                                    onChange={() => handleBankAccountPermission(account.id)}
                                                                />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    ) : (
                                        <p className="text-center">{t("AdminSettings_FirmayaAitBankaHesabiBulunamadi")}</p>
                                    )}
                                </div>
                            )}
                        </TabPane>
                    </TabContent>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleEditUserPopupConfirm} type="submit" disabled={loading || !editedUserFirstname || !editedUserLastname || !editedUserEmail}>
                        {loading ? <Spinner size="sm" className='me-2'> Yükleniyor... </Spinner> : null}
                        {t("AdminSettings_Islemler_Duzenle")}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default Users;