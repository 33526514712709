import axios from "axios";
import * as url from "../../../helpers/url_helper";

export const getUserDataFromAuth = () => async () => {
    try {
        const response = await axios.get(url.GET_PROFILEDATA);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getListWithBanks =async (firmId) =>{
    try {
        const response = await axios.get(url.GET_BANK_ACCOUNT_BY_FIRM_ID+firmId+"?PageIndex=0&PageSize=20");
        console.log(response.data);
        return response.data.items
   
    } catch (err) {
        throw err;
    }
}
export const userFirmBankAccountMatchList = async (firmBankAccountId) => {
    try {
        const response =await axios.get("/user-firm-bank-account-match/list-by-firm-bank-account-id?PageIndex=0&PageSize=20&FirmBankAccountId="+firmBankAccountId)
        console.log(response);
        return response.data
    } catch (err) {
        throw err
    }
}
export const addNotificationPreference = async(body) =>{
    try {
        const response = await axios.post(url.ADD_NOTIFICATION_PREFERENCE,{"notificationPreferences" : body})
        console.log(response);
        return response.data
    } catch (err) {
        throw err
    }
}

export const changeUserInfo = (formData) => async () => {
    try {
        const response = await axios.put(url.PUT_PROFILEDATA, { firstName: formData.firstname, lastName: formData.lastname, password: formData.password, newPassword: formData.newPassword });
        
        if (response) {
            localStorage.removeItem("userData");
            localStorage.removeItem("companyData");
        }

        return response.data;
    } catch (err) {
        throw err;
    }
};

export const enable2FA = () => async () => {
    try {
        const response = await axios.get(url.ENABLE_2FA);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const disable2FA = () => async () => {
    try {
        const response = await axios.post(url.DISABLE_2FA);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const enable2FAConfirm = (code) => async () => {
    try {
        const response = await axios.get(url.VERIFY_2FA + '?AuthenticatorCode=' + code);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const enableEmailAuth = () => async () => {
    try {
        const response = await axios.get(url.ENABLE_EMAILAUTH);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const disableEmailAuth = () => async () => {
    try {
        const response = await axios.post(url.DISABLE_2FA);
        return response.data;
    } catch (err) {
        throw err;
    }
};