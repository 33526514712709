import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Container, Label, Row, Table, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Select from "react-select";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from 'axios';
import * as url from "../../../helpers/url_helper";
import { sendNotify } from '../../../helpers/utils';
import * as XLSX from 'xlsx';
import { getFirmBankAccounts } from "../../../slices/thunks";
import { useDispatch } from 'react-redux';
import Pagination from '../../../Components/Common/Pagination';

const HesapHareketRaporu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    document.title = t("Hesap Hareket Raporu") + " | Tanasoft";

    const [bankaOptions, setBankaOptions] = useState([]);
    const [paraBirimiOptions, setParaBirimiOptions] = useState([]);
    const [banka, setBanka] = useState(null);
    const [paraBirimi, setParaBirimi] = useState(null);
    const [baslangicTarihi, setBaslangicTarihi] = useState(() => {
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return oneWeekAgo.toISOString().split('T')[0];
    });
    const [bitisTarihi, setBitisTarihi] = useState(() => {
        return new Date().toISOString().split('T')[0];
    });
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [filters, setFilters] = useState({
        [t("HesapHareketRaporu_Banka")]: '',
        [t("HesapHareketRaporu_Firma")]: '',
        [t("HesapHareketRaporu_Sube")]: '',
        [t("HesapHareketRaporu_IBAN")]: '',
        [t("HesapHareketRaporu_HesapTuru")]: '',
        [t("HesapHareketRaporu_Bakiye")]: '',
        [t("HesapHareketRaporu_HareketMiktari")]: '',
        [t("HesapHareketRaporu_ParaBirimi")]: '',
        [t("HesapHareketRaporu_SonHareketTarihi")]: '',
        [t("HesapHareketRaporu_HareketsizGunSayisi")]: '',
        [t("HesapHareketRaporu_ReferansNo")]: '',
        [t("HesapHareketRaporu_CariVKN")]: ''
    });

    const [filterModalOpen, setFilterModalOpen] = useState(false);
    const [isFilterActive, setIsFilterActive] = useState(false);

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
                const response = await dispatch(getFirmBankAccounts(companyData.companyID));
                const uniqueBanks = [...new Set(response.map(item => item.bankName).filter(Boolean))];
                const uniqueCurrencies = [...new Set(response.map(item => item.currencyType).filter(Boolean))];

                setBankaOptions(uniqueBanks.map(bank => ({ value: bank, label: bank })));
                setParaBirimiOptions(uniqueCurrencies.map(currency => ({ value: currency, label: currency })));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    const bankaIslem = (selectedSingle) => {
        setBanka(selectedSingle);
    };

    const paraBirimiIslem = (selectedSingle) => {
        setParaBirimi(selectedSingle);
    };

    const EkleIslem = async () => {
        if (!banka || !paraBirimi || !baslangicTarihi || !bitisTarihi) {
            sendNotify(t("HesapHareketRaporu_TumAlanlarDoldurulmali"), 'danger');
            return;
        }

        try {
            const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");

            const tabloResponse = await axios.post(url.GET_BANK_TRANSACTIONS + "?PageIndex=0&PageSize=500", {
                "firms": [companyData.companyID],
                "banks": [],
                "bankBranches": [],
                "firmBankAccounts": [],
                "dynamicQuery": {
                    "sort": [
                        {
                            "field": "date",
                            "dir": "Descending"
                        }
                    ],
                    "filter": {
                        "field": "date",
                        "operator": "LessThanOrEqual",
                        "value": bitisTarihi,
                        "logic": "and",
                        "filters": [
                            {
                                "field": "date",
                                "operator": "GreaterThanOrEqual",
                                "value": baslangicTarihi,
                                "logic": "and",
                                "filters": [
                                    {
                                        "field": "FirmBankAccount.BankBranch.Bank.Name",
                                        "operator": "Equals",
                                        "value": banka.value,
                                        "logic": "and",
                                        "filters": [
                                            {
                                                "field": "currencyType",
                                                "operator": "Equals",
                                                "value": paraBirimi.value
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                }
            });

            if (tabloResponse.data.items) {
                setRecords(tabloResponse.data.items);
                sendNotify(t("HesapHareketRaporu_Basarili"), 'success');
            } else {
                console.error('Error: Data was not fetched.');
            }
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    const clearRecords = () => {
        setRecords([]);
        sendNotify(t("HesapHareketRaporu_TumKayitlarTemizlendi"), 'info');
    };

    const turkishToLower = (str) => {
        return str.replace(/İ/g, 'i')
            .replace(/I/g, 'ı')
            .replace(/Ğ/g, 'ğ')
            .replace(/Ü/g, 'ü')
            .replace(/Ş/g, 'ş')
            .replace(/Ö/g, 'ö')
            .replace(/Ç/g, 'ç')
            .toLowerCase();
    };

    const filteredRecords = records.filter(record =>
        Object.keys(filters).every(key => {
            const filterValue = filters[key];
            if (!filterValue) return true;

            switch (key) {
                case 'Banka':
                    return turkishToLower(record.bankName).includes(turkishToLower(filterValue));
                case 'Firma':
                    return turkishToLower(record.firmName).includes(turkishToLower(filterValue));
                case 'Şube':
                    return turkishToLower(record.branchName).includes(turkishToLower(filterValue));
                case 'IBAN':
                    return record.iban.includes(filterValue);
                case 'Hesap Türü':
                    return turkishToLower(record.accountType).includes(turkishToLower(filterValue));
                case 'Bakiye':
                    return record.balance.toString().includes(filterValue);
                case 'Hareket Miktarı':
                    return record.amount.toString().includes(filterValue);
                case 'Para Birimi':
                    return turkishToLower(record.currencyType).includes(turkishToLower(filterValue));
                case 'Son Hareket Tarihi':
                    return new Date(record.date).toLocaleDateString() === new Date(filterValue).toLocaleDateString();
                case 'Hareketsiz Gün Sayısı':
                    const inactiveDays = Math.floor((new Date() - new Date(record.date)) / (1000 * 60 * 60 * 24));
                    return inactiveDays.toString().includes(filterValue);
                case 'Referans No':
                    return record.referenceNo.includes(filterValue);
                case 'Cari VKN':
                    return record.senderVKN.includes(filterValue);
                default:
                    return true;
            }
        })
    );

    const handleChangePage = (page) => setCurrentPage(page);
    const displayedRecords = filteredRecords.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const formatCurrency = (value, decimals = 2) => {
        return value.toLocaleString('tr-TR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals });
    };

    const handleFilterChange = (e, field) => {
        const newFilters = { ...filters, [field]: e.target.value };
        setFilters(newFilters);
        setIsFilterActive(Object.values(newFilters).some(value => value !== ''));
    };

    const toggleFilterModal = () => {
        setFilterModalOpen(!filterModalOpen);
    };

    const clearFilters = () => {
        setFilters({
            "Banka": '',
            "Firma": '',
            "Şube": '',
            "IBAN": '',
            "Hesap Türü": '',
            "Bakiye": '',
            "Hareket Miktarı": '',
            "Para Birimi": '',
            "Son Hareket Tarihi": '',
            "Hareketsiz Gün Sayısı": '',
            "Referans No": '',
            "Cari VKN": ''
        });
        setIsFilterActive(false);
        sendNotify(t("HesapHareketRaporu_FiltreTemizlendi"), 'info');
    };

    const exportToExcel = () => {
        const excelData = filteredRecords.map(record => ({
            [t("HesapHareketRaporu_Banka")]: record.bankName,
            [t("HesapHareketRaporu_Firma")]: record.firmName,
            [t("HesapHareketRaporu_Sube")]: record.branchName,
            [t("HesapHareketRaporu_IBAN")]: record.iban,
            [t("HesapHareketRaporu_HesapTuru")]: record.accountType,
            [t("HesapHareketRaporu_Bakiye")]: record.balance,
            [t("HesapHareketRaporu_HareketMiktari")]: record.amount,
            [t("HesapHareketRaporu_ParaBirimi")]: record.currencyType,
            [t("HesapHareketRaporu_SonHareketTarihi")]: new Date(record.date).toLocaleDateString(),
            [t("HesapHareketRaporu_HareketsizGunSayisi")]: Math.floor((new Date() - new Date(record.date)) / (1000 * 60 * 60 * 24)),
            [t("HesapHareketRaporu_ReferansNo")]: record.referenceNo,
            [t("HesapHareketRaporu_CariVKN")]: record.senderVKN
        }));

        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, t("Hesap Hareket Raporu"));
        XLSX.writeFile(wb, t("Hesap Hareket Raporu") + ".xlsx");
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Hesap Hareket Raporu")} pageTitle={t("OnlineBanka_Raporlar")} pageLink="online-banka" />
                    <Col xs={12}>
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <div style={{
                                        marginTop: 20,
                                        marginBottom: 20,
                                        marginLeft: 25,
                                        marginRight: 25,
                                    }}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="banka" className="form-label text-capitalize">
                                                        {t("HesapHareketRaporu_Banka")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        placeholder={t("HesapHareketRaporu_BankaSecin")}
                                                        value={banka}
                                                        onChange={bankaIslem}
                                                        options={bankaOptions}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="paraBirimi" className="form-label text-capitalize">
                                                        {t("HesapHareketRaporu_ParaBirimi")}
                                                    </Label>
                                                    <Select
                                                        className="text-capitalize"
                                                        placeholder={t("HesapHareketRaporu_ParaBirimiSecin")}
                                                        value={paraBirimi}
                                                        onChange={paraBirimiIslem}
                                                        options={paraBirimiOptions}
                                                    />
                                                </div>

                                            </Col>
                                            <Col lg={6}>

                                                <div className="mb-3">
                                                    <Label htmlFor="baslangicTarihi" className="form-label text-capitalize">
                                                        {t("HesapHareketRaporu_BaslangicTarihi")}
                                                    </Label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="baslangicTarihi"
                                                        value={baslangicTarihi}
                                                        onChange={(e) => setBaslangicTarihi(e.target.value)}
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="bitisTarihi" className="form-label text-capitalize">
                                                        {t("HesapHareketRaporu_BitisTarihi")}
                                                    </Label>
                                                    <Input
                                                        type="date"
                                                        className="form-control"
                                                        id="bitisTarihi"
                                                        value={bitisTarihi}
                                                        onChange={(e) => setBitisTarihi(e.target.value)}
                                                    />
                                                </div>
                                            </Col>

                                            <div className="text-start">
                                                <Button color="primary" className="text-capitalize me-2" onClick={() => EkleIslem()}>
                                                    {t("HesapHareketRaporu_Listele")}
                                                </Button>
                                            </div>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-end mb-3">
                                            <Button color="secondary" className="text-capitalize me-2" onClick={() => clearRecords()}>
                                                {t("HesapHareketRaporu_Temizle")}
                                            </Button>
                                            <Button
                                                color={isFilterActive ? "warning" : "info"}
                                                className="text-capitalize me-2"
                                                onClick={toggleFilterModal}
                                            >
                                                {isFilterActive ? t("HesapHareketRaporu_FiltreAktif") : t("HesapHareketRaporu_Filtrele")}
                                            </Button>
                                            <Button color="success" className="text-capitalize" onClick={exportToExcel}>
                                                {t("HesapHareketRaporu_ExcelEAktar")}
                                            </Button>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="table align-middle table-nowrap table-hover">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_Banka")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_Firma")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_Sube")}</th>
                                                        <th className="text-uppercase">{t("HesapHareketRaporu_IBAN")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_HesapTuru")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_Bakiye")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_HareketMiktari")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_ParaBirimi")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_SonHareketTarihi")}</th>
                                                        {/* <th className="text-capitalize">{t("HesapHareketRaporu_HareketsizGunSayisi")}</th> */}
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_ReferansNo")}</th>
                                                        <th className="text-capitalize">{t("HesapHareketRaporu_CariVKN")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {displayedRecords.length >= 1 ? (
                                                        displayedRecords.map((record, index) => (
                                                            <tr key={index}>
                                                                <td className="text">{record.bankName || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td className="text">{record.firmName || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td className="text">{record.branchName || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td className="text">{record.iban || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td className="text">{record.accountType || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td>{formatCurrency(record.balance) || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td>{formatCurrency(record.amount) || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td className="text">{record.currencyType || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td>{(new Date(record.date).toLocaleDateString('tr-TR', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\./g, '/')) || t("Bakiyeler_Belirtilmemis")}</td>
                                                                {/* <td>{Math.floor((new Date() - new Date(record.date)) / (1000 * 60 * 60 * 24)) || t("Bakiyeler_Belirtilmemis")}</td> */}
                                                                <td>{record.referenceNo || t("Bakiyeler_Belirtilmemis")}</td>
                                                                <td>{record.senderVKN || t("Bakiyeler_Belirtilmemis")}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="12" className="text-center">{t("HesapHareketRaporu_Nodata")}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>

                                            <Pagination
                                                data={records}
                                                currentPage={currentPage}
                                                setCurrentPage={handleChangePage}
                                                perPageData={itemsPerPage}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Container>
            </div>

            <Modal isOpen={filterModalOpen} toggle={toggleFilterModal}>
                <ModalHeader toggle={toggleFilterModal}>{t("HesapHareketRaporu_FiltrelemeTitle")}</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Banka" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_Banka")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Banka"
                                    placeholder={t("HesapHareketRaporu_BankaFiltrele")}
                                    value={filters["Banka"]}
                                    onChange={(e) => handleFilterChange(e, "Banka")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Firma" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_Firma")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Firma"
                                    placeholder={t("HesapHareketRaporu_FirmaFiltrele")}
                                    value={filters["Firma"]}
                                    onChange={(e) => handleFilterChange(e, "Firma")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Şube" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_Sube")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Şube"
                                    placeholder={t("HesapHareketRaporu_SubeFiltrele")}
                                    value={filters["Şube"]}
                                    onChange={(e) => handleFilterChange(e, "Şube")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="IBAN" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_IBAN")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="IBAN"
                                    placeholder={t("HesapHareketRaporu_IBANFiltrele")}
                                    value={filters["IBAN"]}
                                    onChange={(e) => handleFilterChange(e, "IBAN")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Hesap Türü" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_HesapTuru")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Hesap Türü"
                                    placeholder={t("HesapHareketRaporu_HesapTuruFiltrele")}
                                    value={filters["Hesap Türü"]}
                                    onChange={(e) => handleFilterChange(e, "Hesap Türü")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Bakiye" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_Bakiye")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Bakiye"
                                    placeholder={t("HesapHareketRaporu_BakiyeFiltrele")}
                                    value={filters["Bakiye"]}
                                    onChange={(e) => handleFilterChange(e, "Bakiye")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Hareket Miktarı" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_HareketMiktari")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Hareket Miktarı"
                                    placeholder={t("HesapHareketRaporu_HareketMiktariFiltrele")}
                                    value={filters["Hareket Miktarı"]}
                                    onChange={(e) => handleFilterChange(e, "Hareket Miktarı")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Para Birimi" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_ParaBirimi")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Para Birimi"
                                    placeholder={t("HesapHareketRaporu_ParaBirimiFiltrele")}
                                    value={filters["Para Birimi"]}
                                    onChange={(e) => handleFilterChange(e, "Para Birimi")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Son Hareket Tarihi" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_SonHareketTarihi")}
                                </Label>
                                <Input
                                    type="date"
                                    className="form-control"
                                    id="Son Hareket Tarihi"
                                    placeholder={t("HesapHareketRaporu_SonHareketTarihiFiltrele")}
                                    value={filters["Son Hareket Tarihi"]}
                                    onChange={(e) => handleFilterChange(e, "Son Hareket Tarihi")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Hareketsiz Gün Sayısı" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_HareketsizGunSayisi")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Hareketsiz Gün Sayısı"
                                    placeholder={t("HesapHareketRaporu_HareketsizGunSayisiFiltrele")}
                                    value={filters["Hareketsiz Gün Sayısı"]}
                                    onChange={(e) => handleFilterChange(e, "Hareketsiz Gün Sayısı")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Referans No" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_ReferansNo")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Referans No"
                                    placeholder={t("HesapHareketRaporu_ReferansNoFiltrele")}
                                    value={filters["Referans No"]}
                                    onChange={(e) => handleFilterChange(e, "Referans No")}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mb-3">
                                <Label htmlFor="Cari VKN" className="form-label text-capitalize">
                                    {t("HesapHareketRaporu_CariVKN")}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="Cari VKN"
                                    placeholder={t("HesapHareketRaporu_CariVKNFiltrele")}
                                    value={filters["Cari VKN"]}
                                    onChange={(e) => handleFilterChange(e, "Cari VKN")}
                                />
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={clearFilters}>
                        {t("HesapHareketRaporu_FiltreTemizle")}
                    </Button>
                    <Button color="secondary" onClick={toggleFilterModal}>
                        {t("HesapHareketRaporu_Kapat")}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default HesapHareketRaporu;