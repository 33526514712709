import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFirmLicenses } from "../slices/thunks";

const LicenseProtected = ({ children, requiredModule }) => {
    const dispatch = useDispatch();
    const [hasLicense, setHasLicense] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkLicense = async () => {
            try {
                const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
                if (!companyData.companyID) {
                    setLoading(false);
                    return;
                }

                const response = await dispatch(getFirmLicenses(companyData.companyID));
                const activeModules = response
                    .filter(license => new Date(license.endDate) > new Date())
                    .map(license => license.module);

                setHasLicense(activeModules.includes(requiredModule));
                setLoading(false);
            } catch (error) {
                console.error("Firm license check failed:", error);
                setLoading(false);
            }
        };

        checkLicense();
    }, [dispatch, requiredModule]);

    if (loading) {
        return null;
    }

    if (!hasLicense) {
        return <Navigate to="/dashboard" />;
    }

    return children;
};

export default LicenseProtected; 