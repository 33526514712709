import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row, Alert } from 'reactstrap';
import { useDispatch } from "react-redux";
import { getFirmLicenses } from "../../slices/thunks";
import GunSonuBakiyeChart from "./Charts/GunSonuBakiyeChart";
import TahsilatRaporuChart from "./Charts/TahsilatRaporuChart";
import OnlineBakiyeChart from "./Charts/OnlineBakiyeChart";
import IslemTipineGöreChart from './Charts/IslemTipineGoreChart';
import { isSessionExpired } from '../../helpers/api_helper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const DashboardScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [hasNoLicense, setHasNoLicense] = useState(false);
    document.title = "Ana Sayfa | Tanasoft";

    useEffect(() => {
        const checkLicense = async () => {
            try {
                const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
                if (!companyData.companyID) {
                    setHasNoLicense(true);
                    return;
                }

                const response = await dispatch(getFirmLicenses(companyData.companyID));
                const activeModules = response
                    .filter(license => new Date(license.endDate) > new Date());

                // Şu an için sadece online banka modülü var. Ondan ötürü sadece online banka modülünün aktif olup olmadığını kontrol ediyoruz. İlerleyen zamanlarda diğer modüller de eklenirse, bu kontrolü genişletebiliriz. -- Efe
                setHasNoLicense(activeModules.length === 0);
            } catch (error) {
                console.error("Firm license check failed:", error);
                setHasNoLicense(true);
            }
        };

        checkLicense();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (isSessionExpired()) {
                localStorage.removeItem("userData");
                localStorage.removeItem("companyData");
                navigate('/login');
            }
        }, 1000);
    }, [navigate]);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {hasNoLicense ? (
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Alert color="warning">
                                    {t("NoLicenseError")}
                                </Alert>
                            </Col>
                        </Row>
                    ) : (
                        <>
                            <Row>
                                <Col xs={12}>
                                    <div className="h-100">
                                        <Col xs={12}>
                                            <OnlineBakiyeChart></OnlineBakiyeChart>
                                            <Row>
                                                <Col xs={12} lg={6}>
                                                    <Card style={{ height: 630 }}>
                                                        <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                            <Col xs={12}>
                                                                <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                                    {t("Dashboard_IslemTipineGoreHareketler_Gelen")}
                                                                </h6>
                                                            </Col>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div style={{ height: '500px', width: '100%' }}>
                                                                <IslemTipineGöreChart />
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                                <Col xs={12} lg={6}>
                                                    <Card style={{ height: 630 }}>
                                                        <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                            <Col xs={7}>
                                                                <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                                    {t("Dashboard_GunSonuBakiye")}
                                                                </h6>
                                                            </Col>
                                                        </CardHeader>
                                                        <div className="card-body">
                                                            <GunSonuBakiyeChart dataColors='["--vz-success"]' />
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col xs={12}>
                                            <Row>
                                                <Col xs={12} >
                                                    <Card style={{ height: 670 }}>
                                                        <CardHeader className="d-flex align-items-center p-0 mx-3 py-2" style={{ minHeight: 50 }}>
                                                            <Col xs={6}>
                                                                <h6 className="card-title mb-0 text-capitalize" style={{ color: "#7ba6cb" }}>
                                                                    {t("Dashboard_TahsilatRaporu")}
                                                                </h6>
                                                            </Col>
                                                        </CardHeader>
                                                        <div className="card-body">
                                                            <TahsilatRaporuChart dataColors='["--vz-success"]' />
                                                        </div>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default DashboardScreen;