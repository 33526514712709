import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Row, Col, Spinner, Alert } from 'reactstrap';
import { getUserDataFromAuth, getFirmsByUserId } from "../../slices/thunks";
import { useTable } from 'react-table';
import { useNavigate, Link } from "react-router-dom";
import { sendNotify } from "../../helpers/utils";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Pagination from './Pagination';

const SelectCompanyPopup = ({ isOpen, source, toggle }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [companyList, setCompanyList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const fetchCompanyData = async () => {
        setLoading(true);
        await dispatch(getUserDataFromAuth()).then(user => {
            dispatch(getFirmsByUserId(user.id)).then((response) => {
                setCompanyList(response);
                setLoading(false);
            }).catch(err => {
                setError('Bir hata oluştu lütfen daha sonra tekrar deneyin.');
                setLoading(false);
            });
        }).catch(err => {
            setError('Bir hata oluştu lütfen daha sonra tekrar deneyin.');
            setLoading(false);
        });
    };

    const columns = useMemo(
        () => [
            {
                Header: t("SelectCompanyPopup_FirmName"),
                accessor: 'firmName',
                Cell: ({ value }) => value || 'Belirtilmemiş',
            },
            {
                Header: t("SelectCompanyPopup_VKN"),
                accessor: 'firmVKN',
                Cell: ({ value }) => value || 'Belirtilmemiş',
            },
            {
                Header: t("SelectCompanyPopup_Actions"),
                Cell: ({ row }) => (
                    <Button color="primary" onClick={() => handleCompanySelect(row.original)}>{t("SelectCompanyPopup_Choose")}</Button>
                ),
            },
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data: companyList,
        }
    );

    const currentCompanyOptions = rows.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    useEffect(() => {
        if (isOpen) {
            fetchCompanyData();
        }
    }, [isOpen]);

    useEffect(() => {
        if (!loading && !error) {
            if (companyList.length === 0 && source !== "login-with-email") {
                localStorage.removeItem("userData");
                localStorage.removeItem("companyData");
                sendNotify('Hesabınıza bağlı firma bulunamadı. Lütfen firma yöneticinizle iletişime geçin.', 'danger');
                setTimeout(() => {
                    window.location.reload();
                }, 5500);
            } else if (companyList.length === 1 && source === "login") {
                const selectedCompany = companyList[0];
                const selectedCompanyData = {
                    companyID: selectedCompany.firmId,
                    companyName: selectedCompany.firmName,
                    canSelectFirm: false
                };

                localStorage.setItem("companyData", JSON.stringify(selectedCompanyData));
                navigate("/dashboard");
            }
        }
    }, [loading, error, companyList, navigate]);

    const handleCompanySelect = selectedCompany => {
        const selectedCompanyData = {
            companyID: selectedCompany.firmId,
            companyName: selectedCompany.firmName,
            canSelectFirm: true
        };

        localStorage.setItem("companyData", JSON.stringify(selectedCompanyData));
        navigate("/dashboard");
        window.location.reload();
    };

    const handleChangePage = (page) => setCurrentPage(page);

    return (
        <Modal isOpen={isOpen} toggle={() => { }} size="lg">
            <ModalHeader toggle={source !== 'login' && source !== 'login-with-email' ? toggle : null}>{t("SelectCompanyPopup_Title")}</ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={12}>
                        {loading && <Spinner color="primary">{t("SelectCompanyPopup_Loading")}</Spinner>}
                        {error && <Alert color="danger">{error}</Alert>}
                        {!loading && !error && companyList.length > 0 && (
                            <div className="mb-3">
                                <table key={headerGroups.id} {...getTableProps()} className="table">
                                    <thead>
                                        {headerGroups.map(headerGroup => {
                                            const headerProps = headerGroup.getHeaderGroupProps();
                                            const { key, ...rest } = headerProps;
                                            return (
                                                <tr key={key} {...rest}>
                                                    {headerGroup.headers.map(column => {
                                                        const headerProps = column.getHeaderProps();
                                                        const { key, ...rest } = headerProps;
                                                        return (
                                                            <th key={key} {...rest}>
                                                                {column.render('Header')}
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {currentCompanyOptions.map(row => {
                                            prepareRow(row);
                                            const rowProps = row.getRowProps();
                                            const { key, ...rest } = rowProps;
                                            return (
                                                <tr key={key} {...rest}>
                                                    {row.cells.map(cell => {
                                                        const cellProps = cell.getCellProps();
                                                        const { key, ...rest } = cellProps;
                                                        return (
                                                            <td key={key} {...rest}>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <Pagination 
                                    data={companyList}
                                    currentPage={currentPage}
                                    setCurrentPage={handleChangePage}
                                    perPageData={itemsPerPage}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default SelectCompanyPopup;