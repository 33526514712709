import React from 'react';
import { Routes, Route } from "react-router-dom";
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';
import LicenseProtected from './LicenseProtected';
import AdminProtected from './AdminProtected';

const Index = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <NonAuthLayout>
                                    {route.component}
                                </NonAuthLayout>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>

                <Route>
                    {authProtectedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AuthProtected>
                                    <VerticalLayout>
                                        {route.requiredModule ? (
                                            <LicenseProtected requiredModule={route.requiredModule}>
                                                {route.component}
                                            </LicenseProtected>
                                        ) : route.requireAdmin ? (
                                            <AdminProtected>
                                                {route.component}
                                            </AdminProtected>
                                        ) : (
                                            route.component
                                        )}
                                    </VerticalLayout>
                                </AuthProtected>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Route>
            </Routes>
        </React.Fragment>
    );
};

export default Index;