import axios from "axios";
import * as url from "../../../helpers/url_helper";

export const getFirmBankAccounts = (firmId) => async () => {
    try {
        const response = await axios.get(url.GET_BANK_ACCOUNT_BY_FIRM_ID + firmId + "?PageIndex=0&PageSize=2000");
        return response.data.items;
    } catch (err) {
        throw err;
    }
};

export const getFirmBankAccountsByUserId = (userId, balancePage) => async () => {
    try {
        const response = await axios.get(balancePage ? url.GET_BANK_ACCOUNT_BY_USER_ID + `&UserId=${userId}` : url.GET_BANK_ACCOUNT_MATCH_BY_USER_ID + `&UserId=${userId}`);
        return response.data.items;
    } catch (err) {
        throw err;
    }
};

export const getSubFirms = (firmId) => async () => {
    try {
        const response = await axios.get(url.GET_SUB_FIRMS + firmId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getFirmsByUserId = (userId) => async () => {
    try {
        const response = await axios.get(url.GET_FIRMS_BY_USER_ID + `&UserId=${userId}`);
        return response.data.items;
    } catch (err) {
        throw err;
    }
};

export const getFirmLicenses = (firmId) => async () => {
    try {
        const response = await axios.get(url.GET_FIRM_LICENSES + `${firmId}?PageIndex=0&PageSize=500`);
        return response.data.items;
    } catch (err) {
        throw err;
    }
};