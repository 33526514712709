import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, LineElement } from 'chart.js';
import * as url from '../../../helpers/url_helper';
import { Row, Col, Input, Label, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner } from 'reactstrap';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';

ChartJS.register(BarElement, CategoryScale, LinearScale, LineElement);

const combinedOptions = {
    scales: {
        y: {
            beginAtZero: true,
            stacked: true,
            ticks: {
                callback: function (value) {
                    return value.toLocaleString();
                },
                font: {
                    size: 14,
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                color: '#acb5bd'
            },
            grid: {
                color: '#acb5bd',
                borderDash: [2, 2]
            }
        },
        x: {
            stacked: true,
            ticks: {
                font: {
                    size: 14,
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                color: '#acb5bd'
            },
            grid: {
                display: false
            }
        }
    },
    plugins: {
        legend: {
            display: true
        },
        tooltip: {
            callbacks: {
                label: function (tooltipItem) {
                    return tooltipItem.raw.toLocaleString();
                }
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false
};

const colStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
};

const TahsilatRaporuChart = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [dateRange, setDateRange] = useState('daily');
    const [currencyType, setCurrencyType] = useState('TRY');
    const [timeCount, setTimeCount] = useState(25);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [hasData, setHasData] = useState(true);
    const chartRef = useRef(null);

    const dateRangeOptions = [
        { value: 'daily', label: t("Dashboard_TahsilatRaporu_Daily") },
        { value: 'monthly', label: t("Dashboard_TahsilatRaporu_Monthly") },
        { value: 'yearly', label: t("Dashboard_TahsilatRaporu_Yearly") }
    ];

    const generateDateRange = () => {
        let dateArray = [];
        for (let i = timeCount - 1; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            dateArray.push(`${day}/${month}/${year}`);
        }
        return dateArray;
    };

    const generateDateRangeMonthly = () => {
        let dateArray = [];
        for (let i = timeCount - 1; i >= 0; i--) {
            const date = new Date();
            date.setMonth(date.getMonth() - i);
            date.setDate(1);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            dateArray.push(`01/${month}/${year}`);
        }
        return dateArray;
    };

    const generateYearRangeYearly = () => {
        let dateArray = [];
        for (let i = timeCount - 1; i >= 0; i--) {
            const date = new Date();
            date.setFullYear(date.getFullYear() - i);
            dateArray.push(date.getFullYear().toString());
        }
        return dateArray;
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const companyData = JSON.parse(localStorage.getItem("companyData") || "{}");
                const todayDate = (date) => {
                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    const day = String(date.getDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                };

                let dateRangeData;
                switch (dateRange) {
                    case 'daily':
                        dateRangeData = generateDateRange();
                        break;
                    case 'monthly':
                        dateRangeData = generateDateRangeMonthly();
                        break;
                    case 'yearly':
                        dateRangeData = generateYearRangeYearly();
                        break;
                    default:
                        dateRangeData = generateDateRange();
                }

                const response = await axios.post(url.GET_BANK_TRANSACTIONS + "?PageIndex=0&PageSize=500", {
                    "firms": [companyData.companyID],
                    "banks": [],
                    "bankBranches": [],
                    "firmBankAccounts": [],
                    "dynamicQuery": {
                        "filter": {
                            "field": "date",
                            "operator": "LessThanOrEqual",
                            "value": todayDate(new Date())
                        },
                        "sort": [
                            {
                                "field": "date",
                                "dir": "Descending"
                            }
                        ]
                    }
                });

                let responseData = response.data.items;
                const uniqueCurrencies = [...new Set(responseData.map(item => item.currencyType))];
                setCurrencyOptions(uniqueCurrencies.map(currency => ({ value: currency, label: currency })));

                responseData = responseData.filter(item => item.currencyType === currencyType);

                const aggregatedData = responseData.reduce((acc, item) => {
                    const itemDate = new Date(item.date);
                    let formattedDate;
                    if (dateRange === 'monthly') {
                        formattedDate = `${itemDate.getFullYear()}-${String(itemDate.getMonth() + 1).padStart(2, '0')}`;
                    } else if (dateRange === 'yearly') {
                        formattedDate = `${itemDate.getFullYear()}`;
                    } else {
                        formattedDate = item.date.split('T')[0];
                    }
                    if (!acc[formattedDate]) {
                        acc[formattedDate] = [];
                    }
                    acc[formattedDate].push(item);
                    return acc;
                }, {});

                const positiveData = dateRangeData.map(label =>
                    aggregatedData[label]?.reduce((sum, item) => sum + (item.amount >= 0 ? item.amount : 0), 0) || 0
                );
                const negativeData = dateRangeData.map(label =>
                    aggregatedData[label]?.reduce((sum, item) => sum + (item.amount < 0 ? item.amount : 0), 0) || 0
                );

                const lineData = dateRangeData.map(label => {
                    if (aggregatedData[label]) {
                        const lastItem = aggregatedData[label].slice(-1)[0];
                        return lastItem.balance || 0;
                    }
                    return 0;
                });

                if (positiveData.every(val => val === 0) && negativeData.every(val => val === 0) && lineData.every(val => val === 0)) {
                    setHasData(false);
                    setChartData(null);
                } else {
                    setHasData(true);
                    setChartData({
                        labels: dateRangeData,
                        datasets: [
                            {
                                type: 'bar',
                                label: t("Dashboard_TahsilatRaporu_PositiveValues"),
                                data: positiveData,
                                backgroundColor: 'rgba(133, 197, 227, 1)',
                                borderColor: 'rgba(133, 197, 227, 1)',
                                borderWidth: 1,
                                borderRadius: 10,
                                barPercentage: 1,
                                stack: 'combined',
                                hoverBackgroundColor: 'rgba(133, 197, 227, 0.75)',
                            },
                            {
                                type: 'bar',
                                label: t("Dashboard_TahsilatRaporu_NegativeValues"),
                                data: negativeData,
                                backgroundColor: 'rgba(227, 133, 133, 1)',
                                borderColor: 'rgba(227, 133, 133, 1)',
                                borderWidth: 1,
                                borderRadius: 10,
                                barPercentage: 1,
                                stack: 'combined',
                                hoverBackgroundColor: 'rgba(227, 133, 133, 0.75)',
                            },
                            {
                                type: 'line',
                                label: t("Dashboard_TahsilatRaporu_Balance"),
                                data: lineData,
                                borderColor: 'rgba(0, 255, 0, 1)',
                                borderWidth: 2,
                                fill: false,
                                tension: 0.1,
                                pointRadius: 3,
                                pointBackgroundColor: 'rgba(0, 255, 0, 1)',
                                pointBorderColor: '#fff',
                            }
                        ]
                    });
                }
            } catch (error) {
                console.error("Veri çekme hatası:", error);
                setHasData(false);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [dateRange, currencyType, timeCount]);

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    const handleCurrencyChange = (event) => {
        setCurrencyType(event.target.value);
    };

    const handleTimeCountChange = (event) => {
        setTimeCount(event.target.value);
    };

    const handleDropdownAction = (action) => {
        if (action === 'download') {
            const chart = chartRef.current;
            if (chart) {
                const link = document.createElement('a');
                link.href = chart.toBase64Image();
                link.download = 'chart.png';
                link.click();
            }
        } else if (action === 'excel') {
            if (chartData) {
                const ws = XLSX.utils.json_to_sheet(
                    chartData.labels.map((label, index) => ({
                        'Tarih': label,
                        'Pozitif Değerler': chartData.datasets[0].data[index],
                        'Negatif Değerler': chartData.datasets[1].data[index],
                        'Bakiye': chartData.datasets[2].data[index]
                    })),
                    {
                        cellStyles: true
                    }
                );

                const range = XLSX.utils.decode_range(ws['!ref']);
                for (let col = 1; col <= 3; col++) {
                    for (let row = range.s.r + 1; row <= range.e.r; row++) {
                        const cellAddress = { c: col, r: row };
                        const cellRef = XLSX.utils.encode_cell(cellAddress);
                        if (!ws[cellRef]) ws[cellRef] = {};
                        ws[cellRef].z = currencyType === 'USD' ? '$#,##0.00' :
                            currencyType === 'EUR' ? '€#,##0.00' :
                                '₺#,##0.00';
                    }
                }

                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Data');
                XLSX.writeFile(wb, `Tahsilat Raporu - ${currencyType}_${dateRange} - Excel Raporu.xlsx`);
            }
        }
    };

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    return (
        <>
            {
                // loading ? (
                //     <div className="d-flex justify-content-center align-items-center">
                //         <Spinner color="primary" />
                //     </div>
                // ) : 
            (
                <div>
                    <Row className="mb-3">
                        <Col md="2">
                            <Input
                                type="select"
                                id="dateRange"
                                value={dateRange}
                                onChange={handleDateRangeChange}
                            >
                                {dateRangeOptions.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                        <Col md="2" style={{ display: 'flex', alignItems: 'center' }}>
                            <Input
                                type="number"
                                id="timeCount"
                                value={timeCount}
                                onChange={handleTimeCountChange}
                                min="1"
                                max="100"
                                step="1"
                                onInput={(e) => {
                                    if (e.target.value > 100) e.target.value = 100;
                                    if (e.target.value < 1) e.target.value = '';
                                }}
                                placeholder={t("Dashboard_TahsilatRaporu_TimeCount")}
                                style={{ width: '400px' }}
                            />
                        </Col>
                        {hasData && (
                            <Col md="2">
                                <Input
                                    type="select"
                                    id="currencyType"
                                    value={currencyType}
                                    onChange={handleCurrencyChange}
                                >
                                    {currencyOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Input>
                            </Col>
                        )}
                        <Col md={{ size: 3, offset: 3 }} style={{ ...colStyle, justifyContent: 'flex-end' }}>
                            {hasData && (
                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                    <DropdownToggle caret color="primary">
                                        {t("Dashboard_TahsilatRaporu_Export")}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={() => handleDropdownAction('download')}>{t("Dashboard_TahsilatRaporu_Download")}</DropdownItem>
                                        <DropdownItem onClick={() => handleDropdownAction('excel')}>{t("Dashboard_TahsilatRaporu_Excel")}</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {hasData ? (
                                chartData && (
                                    <div style={{ height: '530px' }}>
                                        <Bar
                                            ref={chartRef}
                                            data={chartData}
                                            options={combinedOptions}
                                        />
                                    </div>
                                )
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
                                    <h5>{t("Dashboard_NoData")}</h5>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default TahsilatRaporuChart;
