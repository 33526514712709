import axios from "axios";
import * as url from "../../../helpers/url_helper";

export const getTransactions = (firmId, startDate = null, endDate = null) => async () => {
    try {
        const pageSize = (startDate || endDate) ? 500 : 200;
        const response = await axios.post(url.GET_BANK_TRANSACTIONS + `?PageIndex=0&PageSize=${pageSize}`, {
            "firms": [firmId],
            "banks": [],
            "bankBranches": [],
            "firmBankAccounts": [],
            "dynamicQuery": {
                "sort": [
                    {
                        "field": "date",
                        "dir": "Descending"
                    }
                ],
                "filter": (startDate || endDate) ? {
                    "field": "date",
                    "operator": endDate ? "LessThanOrEqual" : "GreaterThanOrEqual",
                    "value": endDate || startDate,
                    "logic": (startDate && endDate) ? "and" : undefined,
                    "filters": (startDate && endDate) ? [
                        {
                            "field": "date",
                            "operator": "GreaterThanOrEqual",
                            "value": startDate
                        }
                    ] : undefined
                } : undefined,
            }
        });
        return response.data.items;
    } catch (err) {
        throw err;
    }
};

export const getBankBranch = (branchId) => async () => {
    try {
        const response = await axios.get(url.GET_BANK_BRANCH + branchId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getBank = (bankId) => async () => {
    try {
        const response = await axios.get(url.GET_BANK + bankId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getBankAccount = (bankAccountId) => async () => {
    try {
        const response = await axios.get(url.GET_BANK_ACCOUNT + bankAccountId);
        return response.data;
    } catch (err) {
        throw err;
    }
};

export const getBalanceWithBanks = (firmId) => async () => {
    try {
        const response = await axios.post(url.GET_BALANCE_WITH_BANKS, {
            "firmBankAccounts": [], //firma banka hesabı id'leri
            "firms": [firmId], //firma id'leri
            "currencyType": "" //boş verilirse veya hiç verilmezse bütün kurları getirir, dolu ise sadece verilen kuru geitir
        });
        return response.data;
    } catch (err) {
        throw err;
    }
};